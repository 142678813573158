// src/SocketContext.js
import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// Créez le contexte
export const SocketContext = createContext();

export const SocketProvider = ({ children, userData }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Initialiser le socket
        const newSocket = io('https://test-websockets.csibon.app/', {
            transports: ['websocket', 'polling'],
            jsonp: false,
            pingInterval: 5000, // 5 secondes
            pingTimeout: 10000, // 10 secondes
            reconnection: true, // Reconnexion automatique
            reconnectionAttempts: Infinity, // Nombre illimité de tentatives
            reconnectionDelay: 1000, // Délai initial de reconnexion en ms
            reconnectionDelayMax: 5000, // Délai maximal entre les tentatives
            randomizationFactor: 0.5, // Facteur de randomisation du délai
        });

        setSocket(newSocket);

        // Événement de connexion
        newSocket.on('connect', () => {
            console.log('Connecté au serveur Socket.IO');
            // Émettre 'user_connected' une fois connecté
            newSocket.emit('user_connected', { userType: 'admin', userId: "1" });
            console.log('Émission de user_connected avec userType: admin, userId:', userData ? userData.id : null);
        });

        // Événement de déconnexion
        newSocket.on('disconnect', (reason) => {
            console.log('Déconnecté du serveur Socket.IO:', reason);
        });

        // Écouter les messages reçus
        newSocket.on('receive_message', (data) => {
            console.log(`Message reçu de ${data.from}: ${data.message}`);
            // Traitez le message comme vous le souhaitez
        });

        // Fonction pour gérer les changements de visibilité de la page
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                console.log("La page est en arrière-plan ou fermée");
                // Optionnel : Ne pas déconnecter automatiquement
            } else if (document.visibilityState === 'visible') {
                console.log("La page est de retour au premier plan");
                if (!newSocket.connected) {
                    newSocket.connect();
                }
            }
        };

        // Ajouter l'écouteur d'événement pour les changements de visibilité
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Fonction pour gérer la fermeture ou le rafraîchissement de la page
        const handleBeforeUnload = () => {
            console.log("Page est en train de se décharger");
            newSocket.disconnect();
        };

        // Ajouter l'écouteur d'événement pour beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Nettoyer lors du démontage du composant
        return () => {
            newSocket.disconnect();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [userData]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
