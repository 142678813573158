// src/components/Login.js
import React, { useState } from 'react';
import Cookies from 'js-cookie'; // Utiliser js-cookie pour gérer les cookies
import './Login.css'; // Importation des styles CSS pour la page de login

const Login = ({ setLoggedIn, setUserData }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Valeurs codées en dur pour l'email et le mot de passe
  const hardcodedEmail = 'Csibon';
  const hardcodedPassword = 'Feedback2024';

  const handleLogin = () => {
    if (email === hardcodedEmail && password === hardcodedPassword) {
      const userData = {
        id: 1,
        email: hardcodedEmail,
        first_name: 'Admin',
        name: 'User',
      };
      Cookies.set('token', 'dummyToken', { expires: 7 }); // Stocker un token factice dans un cookie pour 7 jours
      setLoggedIn(true);
      setUserData(userData); // Stocker les informations utilisateur
    } else {
      alert('Identifiants incorrects');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Connexion Administrateur</h2>
        <input
          type="text"
          placeholder="Pseudo"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button onClick={handleLogin} className="login-button">Connexion</button>
      </div>
    </div>
  );
};

export default Login;
