// src/App.js
import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import Cookies from 'js-cookie'; // Utiliser js-cookie pour gérer les cookies
import { SocketProvider } from './SocketContext';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Pour vérifier si nous avons terminé la vérification du token

  useEffect(() => {
    // Vérifier la présence d'un cookie d'authentification au chargement de l'application
    const token = Cookies.get('token');
    if (token) {
      // Si un token est présent, définir l'état de connexion à true
      setUserData({
        id: 1,
        email: 'admin@example.com',
        first_name: 'Admin',
        name: 'User'
      });
      setLoggedIn(true);
    }
    setLoading(false); // Terminer la vérification
  }, []);

  const handleLogout = () => {
    Cookies.remove('token'); // Supprimer le cookie
    setLoggedIn(false);
    setUserData(null);
  };

  if (loading) {
    return <div>Chargement...</div>; // Afficher un message de chargement pendant la vérification
  }

  return (
    <div className="App">
      {loggedIn ? (
        <SocketProvider userData={userData}>
          <AdminDashboard userData={userData} onLogout={handleLogout} />
        </SocketProvider>
      ) : (
        <Login setLoggedIn={setLoggedIn} setUserData={setUserData} />
      )}
    </div>
  );
};

export default App;
