// src/components/AdminDashboard.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { SocketContext } from '../SocketContext'; // Importer le contexte Socket
import './AdminDashboard.css';

const AdminDashboard = ({ userData, onLogout }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const selectedConversationRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);

  const socket = useContext(SocketContext); // Utiliser le contexte Socket

  // Récupérer les conversations au montage
  useEffect(() => {
    fetchConversations();
  }, []);

  // Fonction pour récupérer les conversations
  const fetchConversations = async () => {
    try {
      const response = await axios.get('https://backend.csibon.app/beta/admin/conversations_with_unread_counts');
      setConversations(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des conversations:', error);
    }
  };

  // Fonction pour récupérer les messages d'une conversation spécifique
  const fetchMessages = async (conversationId, userId) => {
    try {
      const response = await axios.get(`https://backend.csibon.app/beta/admin/conversations/${conversationId}/messages`);
      const filteredMessages = response.data.filter(msg => msg.user_id !== '167'); // Filtrer les messages de l'admin si nécessaire
      setMessages(filteredMessages.map((msg) => ({
        ...msg,
        id: msg.id ? msg.id.toString() : 'unknown',
      })));
      setSelectedConversation(conversationId);
      selectedConversationRef.current = conversationId;
      setSelectedUserId(userId);

      console.log(`Admin a rejoint la conversation : ${conversationId}`);

      // Mettre à jour le nombre de messages non lus
      setConversations((prevConversations) => prevConversations.map((conv) => {
        if (conv.id === conversationId) {
          return { ...conv, unread_count: 0 };
        }
        return conv;
      }));
    } catch (error) {
      console.error(`Erreur lors de la récupération des messages pour la conversation ${conversationId}:`, error);
    }
  };

  // Fonction pour envoyer un message
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    try {
      const response = await axios.post('https://backend.csibon.app/beta/messages', {
        beta_conversation_id: selectedConversation,
        content: newMessage,
        user_id: '167', // ID de l'admin
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: response.data.beta_message_id.toString(),
          user_id: '167',
          content: newMessage,
          user_first_name: 'Admin',
          user_name: '',
          created_at: new Date().toISOString(),
          beta_conversation_id: selectedConversation,
        }
      ]);
      setNewMessage('');

      // Mettre à jour le nombre de messages non lus
      setConversations((prevConversations) => prevConversations.map((conv) => {
        if (conv.id === selectedConversation) {
          return { ...conv, unread_count: 0 };
        }
        return conv;
      }));
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
    }
  };

  // // Écouter l'événement 'message_feedback_envoye' pour recharger les conversations et messages
  // useEffect(() => {
  //   if (!socket) return;

  //   // const handleMessageFeedbackEnvoye = (data) => {
  //   //   alert("message reçus")
  //   //   // console.log('Événement message_feedback_envoye reçu:', data);
  //   //   // // Recharger les conversations
  //   //   // fetchConversations();

  //   //   // // Recharger les messages de la conversation sélectionnée si elle est toujours sélectionnée
  //   //   // if (selectedConversationRef.current) {
  //   //   //   // Trouver l'ID utilisateur associé à la conversation sélectionnée
  //   //   //   const currentConv = conversations.find(conv => conv.id === selectedConversationRef.current);
  //   //   //   const userId = currentConv ? currentConv.user_id : null;
  //   //   //   fetchMessages(selectedConversationRef.current, userId);
  //   //   // }
  //   // };

  //   const handleMessageFeedbackEnvoye = (data) => {
  //     console.log('message_feedback_envoye reçu', data);
  //     alert('Message feedback reçu');
  // };
  

  //   // Écouter l'événement 'message_feedback_envoye'
  //   socket.on('message_feedback_envoye', handleMessageFeedbackEnvoye);

  //   // Nettoyer l'écouteur lors du démontage ou lorsque socket change
  //   return () => {
  //     // socket.off('message_feedback_envoye', handleMessageFeedbackEnvoye);
  //   };
  // }, [socket]);


  useEffect(() => {
      const handleMessageFeedbackEnvoye = () => {
      // alert("message reçus")
      // console.log('Événement message_feedback_envoye reçu:', data);
      // Recharger les conversations
      fetchConversations();

     
    };
    
    const handleActionMessage = async (data) => {
        const { from, message_type, user_type } = data;
        // Traitez le message en fonction du type
        console.log('Message d\'action reçu :', data);

        // Exemple de traitement
        switch (message_type) {
            case 'message_feedback_envoye':

            await handleMessageFeedbackEnvoye()
      
                break;
           
            // Ajoutez les autres cas selon vos besoins
            default:
                break;
        }
    };

    if (socket) {
        socket.on('action_message', handleActionMessage);
    }

    // Nettoyer lors du démontage du composant
    return () => {
        if (socket) {
            socket.off('action_message', handleActionMessage);
        }
    };
}, [socket]);

  

  return (
    <div className="dashboard-container">
      <div className="top-bar">
        <button onClick={onLogout} className="logout-button">Déconnexion</button>
      </div>
      <div className="content">
        <div className="conversations-list">
          <h3>Conversations</h3>
          <ul>
            {conversations.length > 0 ? (
              conversations.map((conv) => (
                <li
                  key={conv.id}
                  onClick={() => fetchMessages(conv.id, conv.user_id)}
                  className={`conversation-item ${selectedConversation === conv.id ? 'selected' : ''}`}
                >
                  Conversation avec {conv.user_first_name} {conv.user_name} ({conv.unread_count} non lus)
                </li>
              ))
            ) : (
              <li>Aucune conversation disponible</li>
            )}
          </ul>
        </div>
        <div className="messages-list">
          {selectedConversation ? (
            <>
              <h3>Messages dans la conversation {selectedConversation}</h3>
              <ul>
                {messages.length > 0 ? (
                  messages.map((msg) => (
                    <li
                      key={msg.id}
                      className={`message-item ${msg.user_id === selectedUserId ? 'user-message' : 'admin-message'}`}
                    >
                      <div className="message-info">
                        <strong>{msg.user_first_name} {msg.user_name}</strong>
                      </div>
                      <span>{msg.content}</span>
                    </li>
                  ))
                ) : (
                  <li>Aucun message dans cette conversation</li>
                )}
              </ul>
              <div className="message-input-container">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Écrire un message..."
                  className="message-input"
                />
                <button onClick={handleSendMessage} className="send-button">Envoyer</button>
              </div>
            </>
          ) : (
            <p>Sélectionnez une conversation pour voir les messages</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
